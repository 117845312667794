import { useState, useMemo, useCallback, useEffect, useRef, memo } from "react";
import Station from "./Station";
import Carousel from "./Carousel";
import moment from "moment";
import cn from "classnames";
import _throttle from "lodash.throttle";
import _debounce from "lodash.debounce";
import { LoadingIndicator } from "components";

import "./web-client.scss";
import { useClientDispatch, useClientSelector } from "webclient-store/hooks";
import {
  fetchCategories,
  fetchStations,
  selectCategories,
  selectChannels,
  selectCurrentVideo,
  selectIsLoading,
  selectIsLoadingCategories,
  watchVideo,
} from "./slice";
import type { Categories, Channel } from "common/types";
import CategoryRow from "./CategoryRow";
import { Link } from "react-router-dom";
import VideoScreen from "./VideoScreen";
import { remToPx } from "polished";

const HomePage = memo(() => {
  const isLoading = useClientSelector(selectIsLoading);
  const dispatch = useClientDispatch();
  useEffect(() => {
    dispatch(fetchStations());
  }, [dispatch]);

  const currentVideo = useClientSelector(selectCurrentVideo);
  const channels = useClientSelector(selectChannels);
  const dispatchVideo = useCallback(
    (item: Categories.CategoryItem | Channel.Channel) => {
      dispatch(watchVideo(item));
    },
    [dispatch]
  );

  useEffect(() => {
    if (channels[0] && !currentVideo) {
      dispatch(watchVideo(channels[0]));
    }
  }, [dispatch, channels, currentVideo]);

  useEffect(() => {
    if (channels.length) {
      let tilNextUpdate = channels
        .filter((ch) => !!ch.listings)
        .map((ch) => moment.utc(ch.listings![ch.listings!.length - 1].endTime))
        .reduce((acc, curr) => (curr.isBefore(acc) ? curr : acc))
        .diff(moment());

      if (tilNextUpdate <= 0) {
        tilNextUpdate = 1000;
      }
      const timeOut = setTimeout(() => {
        dispatch(fetchStations());
      }, tilNextUpdate);
      return () => {
        clearTimeout(timeOut);
      };
    }
  }, [channels, dispatch]);

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  const categories = useClientSelector(selectCategories);
  const displayedCategories = useMemo(
    () => categories.filter((item) => item.categoryItems.length),
    [categories]
  );

  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    const onScroll = _throttle(() => {
      setIsScrolled(window.scrollY > 0);
    }, 100);
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const [videoWrapperHeight, setVideoWrapperHeight] = useState(0);
  const videoWrapperRef = useRef<HTMLDivElement>();
  useEffect(() => {
    const observeSize = _debounce(() => {
      if (videoWrapperRef.current) {
        setVideoWrapperHeight(videoWrapperRef.current.clientHeight);
      }
    }, 100);
    window.addEventListener("resize", observeSize);
    observeSize();
    return () => {
      window.removeEventListener("resize", observeSize);
    };
  }, [currentVideo]);

  const isLoadingCategories = useClientSelector(selectIsLoadingCategories);
  return (
    <div>
      {isLoading ? (
        <div className="center">
          <LoadingIndicator />
        </div>
      ) : (
        <div
          className={cn("content", { scroll: isScrolled })}
          style={{
            marginTop: `${videoWrapperHeight + parseInt(remToPx(8))}px`,
          }}
        >
          <div
            className="video-wrapper"
            ref={(el) => {
              if (el) {
                videoWrapperRef.current = el;
                setVideoWrapperHeight(videoWrapperRef.current.clientHeight);
              }
            }}
          >
            <VideoScreen />
          </div>
          <div className="category-wrapper">
            <div className="wrapper-title">
              <h3>Live</h3>
              <Link className="link" to="/live-stations">
                View All
                <svg width="21.7px" height="20.9px" viewBox="0 0 21.7 20.9">
                  <path d="M1.9,0.4l9.6,9.1c0.3,0.3,0.5,0.7,0.5,1.1s-0.2,0.9-0.5,1.1l-9.6,9.2L0,18.7l8.3-8L0,2.7L1.9,0.4z" />
                  <path d="M11.6,0l9.6,9.1c0.3,0.3,0.5,0.7,0.5,1.1s-0.2,0.9-0.5,1.1l-9.6,9.2l-1.9-2.2l8.3-8l-8.3-8L11.6,0z" />
                </svg>
              </Link>
            </div>
            <Carousel>
              {channels.map((ch) => (
                <Station key={ch.id} channel={ch} onClick={dispatchVideo} />
              ))}
            </Carousel>
          </div>
          <div>
            {isLoadingCategories ? (
              <div className="center-block">
                <LoadingIndicator />
              </div>
            ) : (
              displayedCategories.map((category) => (
                <CategoryRow
                  onClick={dispatchVideo}
                  key={`${category.tag.id}_${category.name}`}
                  category={category}
                />
              ))
            )}
          </div>
        </div>
      )}
    </div>
  );
});

export default HomePage;
