import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from "@reduxjs/toolkit";
import { IpOverrides } from "common/types";
import { selectCurrentOrgUnit } from "pages/admin/settings/orgUnitSlice";
import { AdminRootState, SliceBase } from "admin-store";
import * as api from "common/api";
import { selectToken, setUserData } from "pages/admin/login/slice";

type IpOverrideState = SliceBase<IpOverrides.IpOverride>;

const initialState: IpOverrideState = {
  data: [],
  pageCount: 0,
  isLoading: false,
  error: undefined,
};

export const getIpOverridesList = createAsyncThunk(
  "ipOverride/getList",
  async ({ page, size }: { page: number; size: number }, thunkApi) => {
    const token = selectToken(thunkApi.getState() as AdminRootState)!;
    const orgUnitId = selectCurrentOrgUnit(
      thunkApi.getState() as AdminRootState
    );
    return api.getIpOverrides({ token, orgUnitId, page, size });
  }
);

// export const attachPage = createAsyncThunk(
//   "ipOverride/attachPage",
//   async ({ size }: { size: number }, { getState }) => {
//     const token = selectToken(getState() as AdminRootState)!;
//     const orgUnitId = selectCurrentOrgUnit(getState() as AdminRootState);

//     const tagsList = selectTagsList(getState() as AdminRootState);
//     const pageCount = selectPageCount(getState() as AdminRootState);
//     const page = Math.ceil(tagsList.length / size);
//     if (page < pageCount) {
//       return api.getTags({ token, orgUnitId, page, size });
//     }
//     return { data: [], pageCount };
//   }
// );

export const deleteIpOverride = createAsyncThunk(
  "ipOverride/delete",
  async (ip: string, thunkApi) => {
    const token = selectToken(thunkApi.getState() as AdminRootState)!;
    return api.deleteIpOverride({ token, ip });
  }
);

export const createIpOverride = createAsyncThunk(
  "ipOverride/create",
  async (ipOverride: IpOverrides.IpOverride, { getState, signal }) => {
    signal.addEventListener("abort", () => {
      api.cancel();
    });
    const token = selectToken(getState() as AdminRootState)!;
    return api.createIpOverride({ token, ipOverride });
  }
);

export const updateIpOverride = createAsyncThunk(
  "ipOverride/update",
  async (ipOverride: IpOverrides.IpOverride, { getState, signal }) => {
    signal.addEventListener("abort", () => {
      api.cancel();
    });
    const token = selectToken(getState() as AdminRootState)!;
    return api.updateIpOverride({ token, ipOverride });
  }
);

const ipOverridesSlice = createSlice({
  name: "ipOverridesReducer",
  initialState,
  reducers: {
    clearError(state) {
      state.error = undefined;
    },
  },
  extraReducers: (build) => {
    build
      .addCase(getIpOverridesList.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getIpOverridesList.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload.data;
        state.pageCount = payload.pageCount;
      })
      .addCase(getIpOverridesList.rejected, (state, { error }) => {
        state.isLoading = false;
        state.error = error.message;
      })
      // .addCase(attachPage.pending, (state) => {
      //   state.isLoading = true;
      // })
      // .addCase(attachPage.fulfilled, (state, { payload }) => {
      //   state.isLoading = false;
      //   payload.data.forEach((item) => {
      //     state.data.push(item);
      //   });
      //   state.pageCount = payload.pageCount;
      // })
      // .addCase(attachPage.rejected, (state, { error }) => {
      //   state.isLoading = false;
      //   state.error = error.message;
      // })
      .addCase(deleteIpOverride.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteIpOverride.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = state.data.filter(({ ip }) => ip !== payload);
      })
      .addCase(deleteIpOverride.rejected, (state, { error }) => {
        state.isLoading = false;
        state.error = error.message;
      })
      .addCase(updateIpOverride.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(updateIpOverride.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const idx = state.data.findIndex(({ ip }) => ip === payload.ip);
        if (idx >= 0) state.data[idx] = payload;
      })
      .addCase(updateIpOverride.rejected, (state, { error }) => {
        state.isLoading = false;
        if (error.name !== "AbortError") state.error = error.message;
      })
      .addCase(createIpOverride.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(createIpOverride.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data.push(payload);
      })
      .addCase(createIpOverride.rejected, (state, { error }) => {
        state.isLoading = false;
        if (error.name !== "AbortError") state.error = error.message;
      })
      .addCase(setUserData, (state) => {
        Object.assign(state, initialState);
      });
  },
});

export const { clearError } = ipOverridesSlice.actions;

const sliceSelector = (state: AdminRootState) => state.ipOverridesReducer;

export const selectIpOverridesList = createSelector(
  sliceSelector,
  ({ data }) => data
);

export const selectIsLoading = createSelector(
  sliceSelector,
  ({ isLoading }) => isLoading
);

export const selectError = createSelector(sliceSelector, ({ error }) => error);

export const selectPageCount = createSelector(
  sliceSelector,
  (state) => state.pageCount
);

export default ipOverridesSlice.reducer;
