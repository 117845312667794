import { memo } from "react";

const UsersIcon = memo(() => (
  <svg
    className="users-icon"
    width="27.91"
    height="19.532"
    viewBox="0 0 27.91 19.532"
  >
    <path
      className="stroke"
      d="M2074.47,152.913c5.96,0,5.96,9.035,0,9.035S2068.52,152.913,2074.47,152.913Zm-7.93,17.368h-0.04c0.63-10.14,15.78-10.179,16.37.039"
      transform="translate(-2065.5 -151.906)"
    />
    <path
      className="stroke opacity"
      d="M2086.08,154c4.49,0,4.49,6.816,0,6.816S2081.58,154,2086.08,154Zm-3.29,9.37c3.55-2.208,9.31-.618,9.63,4.813"
      transform="translate(-2065.5 -151.906)"
    />
  </svg>
));

const TagsIcon = memo(() => (
  <svg
    className="tags-icon"
    width="24.68"
    height="24.657"
    viewBox="0 0 24.68 24.657"
  >
    <path
      className="stroke"
      d="M2067.92,213.721v8.042a2.922,2.922,0,0,0,.86,2.068l8.04,8.042a2.919,2.919,0,0,0,4.13,0l8.78-8.773a2.926,2.926,0,0,0,0-4.134l-8.05-8.041a2.905,2.905,0,0,0-2.06-.859h-8.04A3.658,3.658,0,0,0,2067.92,213.721Z"
      transform="translate(-2066.91 -209.062)"
    />
    <circle className="fill opacity" cx="8.65" cy="8.625" r="2.22" />
  </svg>
));

const IpIcon = memo(() => (
  <svg
    className="ip-icon"
    xmlns="http://www.w3.org/2000/svg"
    width="24.68"
    height="24.657"
    viewBox="0 0 24.68 24.657"
  >
    <rect
      x="2"
      y="6"
      width="20.68"
      height="12.657"
      rx="2"
      ry="2"
      className="stroke"
      fill="none"
      stroke="currentColor"
      stroke-width="1.5"
    />

    <text
      x="50%"
      y="50%"
      text-anchor="middle"
      fill="currentColor"
      font-size="5"
      font-family="Arial, sans-serif"
      dy=".3em"
    >
      192.168
    </text>
  </svg>
));

const CategoriesIcon = memo(() => (
  <svg
    className="categories-icon"
    width="23.7px"
    height="20.6px"
    viewBox="0 0 23.7 20.6"
  >
    <line className="stroke" x1="8.6" y1="3.3" x2="23.7" y2="3.3" />
    <line className="stroke" x1="8.6" y1="10.3" x2="23.7" y2="10.3" />
    <line className="stroke opacity" x1="8.6" y1="17.3" x2="23.7" y2="17.3" />
    <circle className="fill" cx="3.3" cy="3.3" r="2.3" />
    <circle className="fill" cx="3.3" cy="10.3" r="2.3" />
    <circle className="fill opacity" cx="3.3" cy="17.3" r="2.3" />
  </svg>
));

const BurgerIcon = memo(({ toggleMenu }: { toggleMenu: () => void }) => (
  <div className="main-nav-icon" onClick={toggleMenu}>
    <div />
  </div>
));

const AnalyticsIcon = memo(() => (
  <svg
    className="sources-icon"
    width="23.6px"
    height="23.6px"
    viewBox="0 0 60 60"
  >
    <g>
      <path
        className="stroke"
        d="M56.5,49L56.5,49V1c0-0.6-0.4-1-1-1h-45c-0.6,0-1,0.4-1,1v14h2V2h43v46h-9c-0.6,0-1,0.4-1,1v9h-33V43h-2v16
			c0,0.6,0.4,1,1,1h35c0.3,0,0.5-0.1,0.7-0.3l10-10c0.1-0.1,0.1-0.2,0.2-0.3v-0.1C56.5,49.2,56.5,49.1,56.5,49z M46.5,50h6.6
			l-3.3,3.3l-3.3,3.3L46.5,50L46.5,50z"
      />
      <path
        className="stroke"
        d="M16.5,38h6h4v-2h-3V17c0-0.6-0.4-1-1-1h-6c-0.6,0-1,0.4-1,1v6h-5c-0.6,0-1,0.4-1,1v4h-5c-0.6,0-1,0.4-1,1v8
			c0,0.6,0.4,1,1,1h6H16.5z M17.5,18h4v18h-4V24V18z M11.5,25h4v11h-4v-7V25z M5.5,30h4v6h-4V30z"
      />
      <path
        className="stroke"
        d="M50.5,24V7c0-0.6-0.4-1-1-1h-21c-0.6,0-1,0.4-1,1v17c0,0.6,0.4,1,1,1h21C50.1,25,50.5,24.6,50.5,24z M48.5,12h-12V8h12V12
			z M34.5,8v4h-5c0-1.6,0-4,0-4H34.5z M29.5,14h5v9h-5C29.5,23,29.5,18.3,29.5,14z M36.5,23v-9h12v9H36.5z"
      />
      <rect x="28.5" y="28" width="21" height="2" />
      <rect x="28.5" y="33" width="21" height="2" />
      <rect x="28.5" y="38" width="21" height="2" />
      <rect x="14.5" y="6" width="6" height="2" />
      <rect x="14.5" y="11" width="9" height="2" />
      <rect x="14.5" y="43" width="7" height="2" />
      <rect x="24.5" y="43" width="7" height="2" />
      <rect x="34.5" y="43" width="7" height="2" />
      <rect x="14.5" y="48" width="7" height="2" />
      <rect x="24.5" y="48" width="7" height="2" />
      <rect x="34.5" y="48" width="7" height="2" />
      <rect x="14.5" y="53" width="7" height="2" />
      <rect x="24.5" y="53" width="7" height="2" />
      <rect x="34.5" y="53" width="7" height="2" />
    </g>
  </svg>
));

const SourcesIcon = memo(() => (
  <svg
    className="sources-icon"
    width="23.6px"
    height="26.6px"
    viewBox="0 0 23.6 26.6"
  >
    <path
      className="stroke"
      d="M11.3,1c6.2,0,11.3,2.1,11.3,4.7s-5.1,4.7-11.3,4.7C5,10.5,1,8.3,1,5.7S5,1,11.3,1z M22.6,20.8
      c0,2.6-5.1,4.7-11.3,4.7C5,25.6,1,23.5,1,20.8 M1,5.9v15 M22.6,5.7v15.1"
    />
    <path
      className="stroke opacity"
      d="M22.6,13.3c0,2.6-5.1,4.7-11.3,4.7C5,18,1,15.9,1,13.3"
    />
  </svg>
));

const LiveSourcesIcon = memo(() => (
  <svg
    className="live-sources-icon"
    width="27px"
    height="19.2px"
    viewBox="0 0 27 19.2"
  >
    <path
      className="stroke"
      d="M26,17.2l-7.2-4v4.2c0,0.4-0.4,0.8-0.8,0.8l0,0H3.4c-1.3,0-2.4-1.1-2.4-2.4V3.4
      	C1,2.1,2.1,1,3.4,1H18c0.4,0,0.8,0.4,0.8,0.8c0,0,0,0,0,0V6L26,2V17.2z"
    />
    <path
      className="fill opacity"
      d="M6.5,10.5L6.5,10.5c1.2,0,2.2,1,2.2,2.2v0c0,1.2-1,2.2-2.2,2.2h0c-1.2,0-2.2-1-2.2-2.2v0
      	C4.3,11.5,5.3,10.5,6.5,10.5z"
    />
  </svg>
));

const AgentsIcon = memo(() => (
  <svg
    className="agents-icon"
    width="27.5px"
    height="23.812px"
    viewBox="0 0 27.5 23.812"
  >
    <path
      className="stroke"
      d="M2072.32,578.931h15.75a1.874,1.874,0,0,1,1.8,1.574c0.1,0.813.18,1.627,0.25,2.443m-19.84-.074q0.09-1.187.24-2.369a1.874,1.874,0,0,1,1.8-1.574"
      transform="translate(-2066.44 -577.938)"
    />
    <path
      className="stroke"
      d="M2070.24,582.844h19.92a2.373,2.373,0,0,1,2.28,1.991,48.577,48.577,0,0,1,0,13.938,2.384,2.384,0,0,1-2.29,1.991h-19.91a2.384,2.384,0,0,1-2.29-1.991,48.577,48.577,0,0,1,0-13.938,2.383,2.383,0,0,1,2.29-1.991"
      transform="translate(-2066.44 -577.938)"
    />
    <path
      className="fill opacity"
      d="M2077.25,586.517v9.951l7.4-4.82Z"
      transform="translate(-2066.44 -577.938)"
    />
  </svg>
));

const AssetsIcon = memo(() => (
  <svg
    className="assets-icon"
    width="27.5px"
    height="21.906px"
    viewBox="0 0 27.5 21.906"
  >
    <path
      className="stroke"
      d="M2070.24,396.844h19.92a2.373,2.373,0,0,1,2.28,1.991,63.44,63.44,0,0,1,0,15.938,2.384,2.384,0,0,1-2.29,1.991h-19.91a2.384,2.384,0,0,1-2.29-1.991,63.44,63.44,0,0,1,0-15.938,2.383,2.383,0,0,1,2.29-1.991"
      transform="translate(-2066.44 -395.844)"
    />
    <circle className="fill opacity" cx="18.06" cy="6.656" r="2" />
    <path
      className="fill opacity"
      d="M2070.5,412.618l5.88-8.236,4.71,5.883,3.53-3.53,5.88,5.883h-20Z"
      transform="translate(-2066.44 -395.844)"
    />
  </svg>
));

const SettingsIcon = memo(() => (
  <svg
    className="settings-icon"
    width="22.6px"
    height="23.3px"
    viewBox="0 0 22.6 23.3"
  >
    <path
      className="stroke"
      d="M0,4.4h4.3 M11.6,4.4h11 M0,11.7h22.6 M8,1c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4
      c-1.9,0-3.4-1.5-3.4-3.4c0,0,0,0,0,0C4.6,2.5,6.1,1,8,1L8,1z"
    />
    <path
      className="stroke opacity"
      d="M14.6,15.5c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4s-3.4-1.5-3.4-3.4C11.2,17.1,12.7,15.5,14.6,15.5z
       M0,18.9h11 M18,18.9h4.6"
    />
  </svg>
));

export {
  UsersIcon,
  TagsIcon,
  CategoriesIcon,
  SourcesIcon,
  LiveSourcesIcon,
  AgentsIcon,
  AssetsIcon,
  SettingsIcon,
  BurgerIcon,
  AnalyticsIcon,
  IpIcon,
};
